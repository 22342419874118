import { render, staticRenderFns } from "./formCensus.vue?vue&type=template&id=53ae36fe&scoped=true&"
import script from "./formCensus.vue?vue&type=script&lang=js&"
export * from "./formCensus.vue?vue&type=script&lang=js&"
import style0 from "./formCensus.vue?vue&type=style&index=0&id=53ae36fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ae36fe",
  null
  
)

export default component.exports