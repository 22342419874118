<template>
    <div class="survey home-grid">
        <div class="survey-left">
            <div class="s-top">
                <h2>欢迎登录系统</h2>
                <span>{{ "上次登录：" +platform.last_time }}</span>
            </div>
            <div class="s-bottom cross-center">
                <div class="main-between cross-center">
                    <img class="logo" src="@/assets/images/icon/logo.png" />
                    <div class="expira">
                        <label>{{mallName}}</label>
                        <span>{{ "到期时间：" + calExtime(Extime) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="survey-right">
            <div class="title cross-center">
                <div></div>
                <label>实时概况</label>
                <!-- <span>更新于200</span> -->
            </div>
            <div class="user-data cross-center">
                <div class="total-user">
                    <label>总用户数(人)</label>
                    <span>{{platformUC}}</span>
                </div>
                <div class="new-user">
                    <label>今日新增用户数(人)</label>
                    <span>{{platformUNC}}</span>
                </div>
            </div>
            <div class="usernum-chart" ref="userChart"></div>
        </div>
    </div>
</template>

<script>
import {sessionGetKey} from '@/utils/sessionStorage.js'
export default {
    props: {
        charData:{
            type:Array,
            default:()=>{
                return []
            }
        },
        platformUC:{
            type:Number,
            default:0
        },
        platformUNC:{
            type:Number,
            default:0
        },
        mallName:{
            type:String,
            default:'商城名称'
        },
        platform:{
            type:[Object,Array]
        }
    },
    data() {
        return {
            Extime:''
        };
    },
    watch:{
        charData(newVal,odlVal){
            this.userChar();
        }
    },
    mounted(){
        this.Extime = sessionGetKey('time')
        this.userChar();
    },
    methods: {
        userChar() {
            let allData = []
            let newData = []
            let date = []
            
            this.charData.forEach((item)=>{
                allData.push(item.all)
                newData.push(item.new)
                date.push(item.date)
            })

            let userChart = this.$echarts.init(this.$refs.userChart);
            let option = {
                legend: {
                    selectedMode: true, //可点击
                    data: ["总用户数", "今日新增用户数"],
                    // top: '10',
                    right: "0",
                },
                tooltip: {
                    icon: "circle",
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        label: {
                            backgroundColor: "#6a7985",
                        },
                    },
                },
                grid: {
                    top: "30",
                    left: "15",
                    right: "20",
                    bottom: "10",
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        boundaryGap: false,
                        data: date,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#333333",
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        name: "用户数/人",
                        type: "value",
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#333333",
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "总用户数",
                        type: "line",
                        stack: "totalUser",
                        smooth: true,
                        // showSymbol: false,  //控制拐点是否显示
                        lineStyle: {
                            width: 3, //外边框的线
                        },
                        itemStyle: {
                            color: "#475cf5", //拐点边框颜色
                        },
                        symbolSize: 8, //拐点大小
                        areaStyle: {
                            opacity: 0.8,
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: "rgb(76, 98, 245)",
                                },
                                {
                                    offset: 1,
                                    color: "rgb(130, 176, 248)",
                                },
                            ]),
                        },
                        emphasis: {
                            focus: "series",
                        },
                        data: allData,
                    },
                    {
                        name: "今日新增用户数",
                        type: "line",
                        stack: "newUser",
                        smooth: true,
                        lineStyle: {
                            width: 3, //外边框的线
                        },
                        itemStyle: {
                                color: "#55b7ea", //拐点边框颜色
                                // borderColor: '#FFFFFF'
                        },
                        symbolSize: 8, //拐点大小
                        areaStyle: {
                            opacity: 0.8,
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: "rgb(202, 225, 252)",
                                },
                                {
                                    offset: 1,
                                    color: "rgb(184, 229, 252)",
                                },
                            ]),
                        },
                        emphasis: {
                            focus: "series",
                        },
                        data: newData,
                    },
                ],
            };
            option && userChart.setOption(option);
            window.addEventListener('resize', function () {
                userChart.resize()
            });
        },
    },
    computed:{
        calExtime(){
            return function(time){
                if(time === '0000-00-00 00:00:00'){
                    return '未知'
                }else{
                    return time
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.survey {
    padding: 20px;
    display: flex;
    height: 404px;
}

.survey-left {
    width: 350px;
    position: relative;

    .s-top {
        position: absolute;
        width: 100%;
        height: 180px;
        line-height: 100px;
        background-image: url("~@/assets/images/home/welcome.png");
        background-size: 100% 120%;

        h2 {
            margin-left: 20px;
            font-size: 24px;
            font-weight: 600;
            color: #ffffff;
            height: 20px;
        }

        span {
            margin-left: 20px;
            font-size: 16px;
            color: #ffffff;
        }
    }

    .s-bottom {
        width: 100%;
        height: 190px;
        position: absolute;
        bottom: 0;
        background-image: linear-gradient(to top, #f8fdff, #d8f5ff);
        border-radius: 12px;

        .logo {
            margin-left: 20px;
            width: 76px;
            height: 76px;
        }

        .expira {
            margin-left: 16px;

            label {
                display: block;
            }

            span {
                display: block;
                margin-top: 12px;
            }
        }
    }
}

.survey-right {
    margin-left: 24px;
    flex: 1;

    .title {
        div {
            height: 22px;
            width: 4px;
            background-color: #4458fe;
            margin-right: 14px;
            border-radius: 2px;
        }

        label {
            font-size: 22px;
            font-weight: 600;
        }

        span {
            margin-left: 14px;
            color: #999999;
            font-size: 16px;
        }
    }

    .user-data {
        margin-top: 24px;
        .total-user {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 106px;
            background-color: #edfaff;
        }

        .new-user {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 106px;
            margin-left: 24px;
            background-color: #edfaff;
        }

        label {
            margin-left: 24px;
            font-size: 16px;
        }

        span {
            margin-left: 24px;
            margin-top: 10px;
            font-size: 30px;
            font-weight: 600;
        }
    }

    .usernum-chart {
        width: 100%;
        height: 200px;
        margin-top: 10px;
    }
}

@media screen and (max-width: 1680px) {
    .survey-left {
        min-width: 300px;
    }

    .survey-right {
        width: 550px;
        .title {
            div {
                margin-right: 10px;
            }
            span {
                margin-left: 10px;
            }
        }

        .user-data {
            margin-top: 24px;
            .new-user {
                margin-left: 20px;
            }
            span {
                font-size: 25px;
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .survey-left {
        min-width: 250px;
    }

    .survey-right {
        width: 400px;
        .title {
            div {
                margin-right: 10px;
            }
            span {
                margin-left: 10px;
            }
        }

        .user-data {
            margin-top: 24px;

            .new-user {
                margin-left: 20px;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .survey-left {
        width: 300px;
    }
    .survey-right {  
        width: 400px;   
        .title {
            div {
                margin-right: 10px;
            }
            span {
                margin-left: 10px;
            }
        }

        .user-data {
            .new-user {
                margin-left: 10px;
            }
        }
    }
}
</style>
