<template>
    <div class="hot-ratio home-grid">
        <div class="title cross-center">
            <div class="t-left cross-center">
                <div></div>
                <label>热门产品比例</label>
            </div>
        </div>
        <div class="hot-box">
            <div class="hot-ratio-chart" ref="hotRatioChart"></div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        orderP:{
            type:[Object,Array,String],
            default:()=>{
                return {}
            }
        }
    },
    watch:{
        orderP(){
            this.hotChart()      
        }
    },
    mounted() {
        this.hotChart();
    },
    methods: {
        hotChart() {
            let data = []
            for(let key in this.orderP){
                data.push({
                    name:key,
                    value:this.orderP[key].num
                })
            }
            
            let hotRatioChart = this.$echarts.init(this.$refs.hotRatioChart);
            let option = {
                legend: {
                    orient: 'vertical',
                    // top: '5%',
                    right: '30',
                    bottom:'40',
                    icon:'circle',
                    textStyle: { 
                        lineHeight:'20',
                        fontSize:'16'
                    },         
                },
                tooltip: {
                    trigger: "item",
                },
                series: [
                    {
                        name: '热门产品比例',
                        type: "pie",
                        radius: ["50%", "70%"],
                        clockwise: false,
                        selectedMode: "single",
                        label: {
                            fontSize: '16'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '16',
                                fontWeight: 'bold',
                                formatter:'{d}%'
                            }
                        },
                        data: data,
                    },
                ],
            };
            option && hotRatioChart.setOption(option);
            window.addEventListener('resize', function () {
                hotRatioChart.resize()
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.hot-ratio {
    height: 360px;
    padding: 20px;
}

.title {
    height: 30px;
    .t-left {
        div {
            height: 22px;
            width: 4px;
            background-color: #4458fe;
            margin-right: 14px;
            border-radius: 2px;
        }

        label {
            font-size: 22px;
            font-weight: 600;
        }
    }
}

.hot-box {
    width: 100%;
    .hot-ratio-chart {
        width: 100%;
        height: 300px;
    }
}
</style>
