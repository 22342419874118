<template>
    <div class="form-census home-grid">
        <div class="title cross-center">
            <div class="t-left cross-center">
                <div></div>
                <label>表单统计</label>
            </div>
        </div>
        <div class="form-census-box">
            <div class="census-row cross-center">
                <div class="box">
                    <label>总表单数(个)</label>
                    <span>{{orderAll}}</span>
                </div>
                <div class="box">
                    <label>今日新增表单数(个)</label>
                    <span>{{ordersN}}</span>
                </div>
            </div>
            <div class="census-row cross-center">
                <div class="box">
                    <label>已审批</label>
                    <span>{{ordersOK}}</span>
                </div>
                <div class="box">
                    <label>待审批</label>
                    <span>{{ordersNO}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        orderAll:Number,
        ordersN:Number,
        ordersOK:Number,
        ordersNO:Number
    },
    data() {
        return {};
    },
    computed: {},
};
</script>

<style lang="scss" scoped>
.form-census {
    height: 377px;
    padding: 20px;
}

.title {
    .t-left {
        div {
            height: 22px;
            width: 4px;
            background-color: #4458fe;
            margin-right: 14px;
            border-radius: 2px;
        }

        label {
            font-size: 22px;
            font-weight: 600;
        }
    }
}

.form-census-box {
    margin-top: 25px;

    .census-row {
        width: 100%;

        .box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            height: 126px;
            background-color: #edfaff;
            border-radius: 12px;
            label {
                margin-left: 20px;
                font-size: 18px;
            }

            span {
                margin-left: 20px;
                margin-top: 20px;
                font-size: 30px;
                font-weight: 600;
            }

            &:last-child {
                margin-left: 24px;
            }
        }

        &:last-child {
            margin-top: 24px;
        }
    }
}

@media screen and (max-width: 1680px) {
    .form-census-box {
        .census-row {
            .box {
                label {
                    margin-left: 20px;
                    font-size: 17px;
                }

                span {
                    margin-left: 20px;
                    margin-top: 20px;
                    font-size: 27px;
                    font-weight: 600;
                }

                &:last-child {
                    margin-left: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .form-census-box {
        .census-row {
            .box {
                label {
                    margin-left: 10px;
                    font-size: 15px;
                }

                span {
                    margin-left: 10px;
                    margin-top: 20px;
                    font-size: 24px;
                    font-weight: 500;
                }

                &:last-child {
                    margin-left: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .form-census-box {
        .census-row {
            .box {
                label {
                    margin-left: 5px;
                    font-size: 14px;
                }

                span {
                    margin-left: 5px;
                    margin-top: 18px;
                    font-size: 23px;
                    font-weight: 500;
                }

                &:last-child {
                    margin-left: 10px;
                }
            }
        }
    }

}
</style>
