<!--
 * @Author: 喀喀喀 1246444836@qq.com
 * @Date: 2022-07-19 09:29:28
 * @LastEditors: 喀喀喀 1246444836@qq.com
 * @LastEditTime: 2022-07-19 16:56:42
 * @FilePath: /xinxihoutai/src/views/homepage/component/notice.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="notice home-grid">
        <div class="title cross-center main-between">
            <div class="t-left cross-center">
                <div></div>
                <label>公告通知</label>
            </div>
            <!-- <div class="t-rigth" @click="goArticle">
                <span>查看更多</span>
                <img src="@/assets/images/icon/arrow-right-grey.png" />
            </div> -->
        </div>
        <div class="notice-box">
            <div class="box cross-center main-between" v-for="(item, index) in noticeData" :key="index">
                <label>{{ item.title }}</label>
                <span>{{ calTime(item.time) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        noticeData: {
            type: Array,
            default: function () {
                return []
            },
        },
    },
    methods: {
        goArticle() {
            this.$router.push("/article/articleMent");
        },
    },
    computed:{
        calTime(){
            return function(time){
               let arr = time.split(' ')
               if(arr){
                    return arr[0]
               }else {
                return time
               }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.notice {
    height: 404px;
    padding: 20px;
}

.title {
    .t-left {
        div {
            height: 22px;
            width: 4px;
            background-color: #4458fe;
            margin-right: 14px;
            border-radius: 2px;
        }

        label {
            font-size: 22px;
            font-weight: 600;
        }
    }

    .t-rigth {
        cursor: pointer;
        span {
            vertical-align: middle;
            margin-left: 14px;
            color: #999999;
            font-size: 16px;
        }
        img {
            vertical-align: middle;
            width: 24px;
            height: 24px;
        }
    }
}

.notice-box {
    margin-top: 20px;
    .box {
        height: 65px;
        border-top: 4px solid #f3f3f3;
        label {
            flex: 2.5;
            font-size: 16px;
            overflow: hidden; 
            text-overflow: ellipsis; 
            -o-text-overflow: ellipsis;
            white-space:nowrap;
        }
        span {
            flex: 1;
            font-size: 16px;
            text-align: right;
            white-space: nowrap;
        }
    }
}
</style>
