<!--
 * @Author: 喀喀喀 1246444836@qq.com
 * @Date: 2022-07-19 09:29:28
 * @LastEditors: 喀喀喀 1246444836@qq.com
 * @LastEditTime: 2022-07-19 16:51:37
 * @FilePath: /xinxihoutai/src/views/homepage/homepage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="home-main">
        <el-row :gutter="24">
            <el-col :span="16"><survey :charData="userP" :platformUNC="platformUNC" :platform="platformdata" :platformUC="platformUC" :mallName="platformName"></survey></el-col>
            <el-col :span="8"><notice :noticeData="notice"></notice></el-col>
        </el-row>
        <el-row :gutter="24" class="margin-top24">
            <el-col :span="8"><articleTop :articledata="articledata"></articleTop></el-col>
            <el-col :span="8"><formCensus :orderAll="orderAll" :ordersN="ordersN" :ordersOK="ordersOK" :ordersNO="ordersNO"></formCensus></el-col>
            <el-col :span="8"><productCollection :productCollectiondata="productCollectiondata"></productCollection></el-col>
        </el-row>
        <el-row :gutter="24" class="margin-top24">
            <el-col :span="16"><hotProductRatio :orderP="orderP"></hotProductRatio></el-col>
            <el-col :span="8"><customerService :servicedata="servicedata"></customerService></el-col>
        </el-row>
    </div>
</template>

<script>
    import survey from './component/survey.vue'
    import notice from './component/notice.vue'
    import articleTop from './component/articleTop.vue'
    import formCensus from './component/formCensus.vue'
    import productCollection from './component/productCollection'
    import hotProductRatio from './component/hotProductRatio'
    import customerService from './component/customerService'
    import {sessionGetKey} from '@/utils/sessionStorage.js'
    export default {
        components:{
            survey,
            notice,
            articleTop,
            formCensus,
            productCollection,
            hotProductRatio,
            customerService
        },
        data(){
            return{
                platformId:'',
                userP:[],
                servicedata:{},
productCollectiondata:[],
articledata:[],
                platformName:'',
                platformUC:0,
                platformUNC:0,
platformdata:[],
                orderAll:0,
                ordersN:0,
                ordersOK:0,
                ordersNO:0,
                
                orderP:{},

                notice:[],
            }
        },
        mounted(){
            this.platformId = sessionGetKey('platformId')
            console.log(this.platformId);
            this.getHome()
        },
        methods:{
            getHome(){
                const that =this
                that.$request({
                    url:that.$api.home.home,
                    method:'post',
                    params:{
                        platform_id:that.platformId
                    }
                }).then(res=>{
                    // 文章收藏
                    this.productCollectiondata=res.data.articleCollect
                    // 文章浏览
                    this.servicedata=res.data.kf
                    this.articledata=res.data.articleVolume
                    this.userP = res.data.userP
                    this.platformUC = res.data.platformUC
                    this.platformUNC = res.data.platformUNC
                    this.orderAll = res.data.orderAll
                    this.ordersN = res.data.ordersN
                    this.platformdata=res.data.platform[0]
                    this.ordersOK = res.data.ordersOK
                    this.ordersNO = res.data.ordersNO
                    this.orderP = res.data.orderP
                    this.platformName = res.data.platformName
                    this.notice = res.data.notice
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .home-main{
        width: 100%;
        height: 100%;
        padding: 24px;
    }

    .margin-top24{
        margin-top: 24px;
    }
</style>