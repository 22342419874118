<template>
    <div class="article-top home-grid">
        <div class="title cross-center main-between">
            <div class="t-left cross-center">
                <div></div>
                <label>文章浏览量TOP5</label>
            </div>
            <div class="t-rigth">
                <span>查看更多</span>
                <img src="@/assets/images/icon/arrow-right-grey.png" />
            </div>
        </div>
        <div class="article-browse">
            <table class="top5-table" border="0" cellpadding="0" cellspacing="0">
                <tr class="table-title">
                    <th>TOP</th>
                    <th>文章标题</th>
                    <th>浏览量</th>
                </tr>
                <tr class="table-conent" v-for="(item, index) in articledata" :key="index">
                    <td>
                        <img class="top-icon" v-if="index+1 <= 3" :src="topIcon(index+1)" />
                        <span v-else>{{ index+1 }}</span>
                    </td>
                    <td class="article-title">{{item.name}}</td>
                    <td>{{item.volume}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    props:{
        articledata:{
type:[Object,Array]
        },
    },
    computed: {
        topIcon() {
            return function (rand) {
                switch (rand) {
                    case 1:
                        return require("@/assets/images/home/top-one.png");
                    case 2:
                        return require("@/assets/images/home/top-two.png");
                    case 3:
                        return require("@/assets/images/home/top-three.png");
                }
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.article-top {
    height: 377px;
    padding: 20px;
}

.title {
    .t-left {
        div {
            height: 22px;
            width: 4px;
            background-color: #4458fe;
            margin-right: 14px;
            border-radius: 2px;
        }

        label {
            font-size: 22px;
            font-weight: 600;
            overflow: hidden; 
            text-overflow: ellipsis; 
            -o-text-overflow: ellipsis;
            white-space:nowrap;
        }
    }

    .t-rigth {
        cursor: pointer;

        span {
            vertical-align: middle;
            margin-left: 14px;
            color: #999999;
            font-size: 16px;
        }

        img {
            vertical-align: middle;
            width: 24px;
            height: 24px;
        }
    }
}

.article-browse {
    margin-top: 10px;

    .top5-table {
        width: 100%;
        .table-title {
            color: #4458fe;
            font-size: 18px;
            height: 50px;
            th {
                font-weight: 400;
            }
        }

        .table-conent {
            text-align: center;
            font-size: 16px;
            color: #333333;
            height: 50px;
            .top-icon {
                width: 36px;
                height: 36px;
            }
            .article-title {
                max-width: 100px;
                overflow: hidden; 
                text-overflow: ellipsis; 
                -o-text-overflow: ellipsis;
                white-space:nowrap;
            }
        }
    }
}

@media screen and (max-width: 1680px) {

}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
    .title {
        .t-rigth {
            span {
                margin-left: 10px;
            }
        }
    }
}
</style>
