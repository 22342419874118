import { render, staticRenderFns } from "./articleTop.vue?vue&type=template&id=056d2bb4&scoped=true&"
import script from "./articleTop.vue?vue&type=script&lang=js&"
export * from "./articleTop.vue?vue&type=script&lang=js&"
import style0 from "./articleTop.vue?vue&type=style&index=0&id=056d2bb4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "056d2bb4",
  null
  
)

export default component.exports