<template>
  <div class="custome-service home-grid">
    <div class="title cross-center">
      <div class="t-left cross-center">
        <div></div>
        <label>客服服务</label>
      </div>
    </div>
    <div class="custome-box">
      <!-- <div class=""> -->
      <div class="custome-img">
        <img src="@/assets/images/home/customer-portrait.png" />
      </div>
      <p class="contact-name">客服</p>
      <div class="contact-us">
        <el-popover
    placement="top-start"
    width="200"
    :visible-arrow="false"
   popper-class="customer-tip"
    trigger="hover"
    :content="servicedata.phone">
        <div class="call-btn level-vertical-center" slot="reference">
          拨打电话
        </div>
   </el-popover>
        <el-popover
    placement="top-start"
    width="200"
    popper-class="customer-tip"
    trigger="hover"
    :visible-arrow="false"
   >
     <div style="width: 100%; display: flex" >
        <img :src="servicedata.code" style="width: 90%; margin: 0 auto" />
      </div>
        <div class="contact-btn level-vertical-center"  slot="reference">
          联系我们
        </div>
        </el-popover>
      </div>
     
    </div>
    <el-dialog :title="title" :visible.sync="contactuspic" width="30%">
      <div style="width: 100%; display: flex" v-if="img">
        <img :src="img" style="width: 90%; margin: 0 auto" />
      </div>
      <div
        v-else
        style="
          height: 60px;
          align-items: center;
          display: flex;
          justify-content: space-between;
        "
        id="copycode"
        :data-clipboard-text="data"
      >
        <span>{{ data }}</span>
        <el-tag size="small"> <span @click="getcopy">复制</span></el-tag>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from "clipboard";
export default {
  data() {
    return {
      title: "",
      contactuspic: false,
      data: null,
      img: null,
      phone:''
    };
  },
  watch:{

  },
  methods: {
    getcopy() {
      let clipboard = new Clipboard("#copycode");
      clipboard.on("success", (e) => {
        // 释放内存
        this.$message.success('复制成功');
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
         this.$message('该浏览器不支持自动复制');
        // 释放内存
        clipboard.destroy();
      });
    },
    contactus() {
      this.contactuspic = true;
      this.title = "联系我们";
      this.data = null;
      this.img = this.servicedata.code;
      console.log(this.servicedata);
    },
    callphone() {
      this.contactuspic = true;
      this.img = null;
      this.data = this.servicedata.phone;
      this.title = "拨打电话";
      console.log(this.servicedata);
    },
  },
  props: {
    servicedata: {
      type: [Object, Array, String],
    },
  },
};
</script>

<style lang="scss" >
.customer-tip{
 opacity: 0.6;
background: #000!important;
color: #fff;
border-radius: 10px;
overflow: hidden;
padding: 12px!important;
text-align: center;
  }
.custome-service {
  height: 360px;
  padding: 20px;
  /deep/.el-dialog__body {
    padding: 0 30px 20px;
  }
}

.title {
  .t-left {
    div {
      height: 22px;
      width: 4px;
      background-color: #4458fe;
      margin-right: 14px;
      border-radius: 2px;
    }

    label {
      font-size: 22px;
      font-weight: 600;
    }
  }
}

.custome-box {
  height: 290px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .custome-img {
    width: 108px;
    height: 108px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .contact-name {
    margin: 24px 0;
  }

  .contact-us {
    display: flex;
    .call-btn {
      width: 204px;
      height: 56px;
      background-image: linear-gradient(to right, #0de5ca, #01d0b5);
      border-radius: 12px;
      color: #ffffff;
      cursor: pointer;
    }

    .contact-btn {
      width: 204px;
      height: 56px;
      margin-left: 24px;
      background-image: linear-gradient(to right, #2fc9fc, #14b9ef);
      border-radius: 12px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1680px) {
  .custome-box {
    .contact-us {
      .call-btn {
        width: 160px;
      }

      .contact-btn {
        width: 160px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .custome-box {
    .contact-us {
      .call-btn {
        width: 120px;
      }

      .contact-btn {
        width: 120px;
      }
    }
  }
}

@media screen and (max-width: 1366px) {
}
</style>
