<template>
    <div class="product-coll home-grid">
        <div class="title cross-center main-between">
            <div class="t-left cross-center">
                <div></div>
                <label>文章收藏量TOP5</label>
            </div>
            <div class="t-rigth">
                <span>查看更多</span>
                <img src="@/assets/images/icon/arrow-right-grey.png" />
            </div>
        </div>
        <div class="product-table">
            <table class="top5-table" border="0" cellpadding="0" cellspacing="0">
                <tr class="table-title">
                    <th>TOP</th>
                    <th>文章名称</th>
                    <th>收藏量</th>
                </tr>
                <tr class="table-conent" v-for="(item, index) in productCollectiondata" :key="index">
                    <td>
                        <!-- <img class="top-icon" v-if="item<=3" :src="topIcon(item)"> -->
                        <span>{{ index+1}}</span>
                    </td>
                    <td class="td-name">{{item.data.name}}</td>
                    <td>{{item.count}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        productCollectiondata:{
            type:[Object,Array]
        }
    }
};
</script>

<style lang="scss" scoped>
.product-coll {
    padding: 20px 0;
    height: 377px;
}

.title {
    padding: 0 20px;

    .t-left {
        div {
            height: 22px;
            width: 4px;
            background-color: #4458fe;
            margin-right: 14px;
            border-radius: 2px;
        }

        label {
            font-size: 22px;
            font-weight: 600;
        }
    }

    .t-rigth {
        cursor: pointer;

        span {
            vertical-align: middle;
            margin-left: 14px;
            color: #999999;
            font-size: 16px;
        }

        img {
            vertical-align: middle;
            width: 24px;
            height: 24px;
        }
    }
}

.product-table {
    margin-top: 15px;

    .top5-table {
        width: 100%;
        // table-layout: fixed;
        .table-title {
            color: #4458fe;
            font-size: 18px;
            height: 50px;

            th {
                font-weight: 400;
            }
        }

        .table-conent {
            text-align: center;
            font-size: 16px;
            color: #333333;
            height: 50px;

            .top-icon {
                width: 36px;
                height: 36px;
            }

            .td-name{
                max-width: 100px;
                overflow: hidden; 
                text-overflow: ellipsis; 
                -o-text-overflow: ellipsis;
                white-space:nowrap;
            }
        }

        tr:nth-child(even) {
            background-color: #edefff;
        }
    }
}

@media screen and (max-width: 1366px) {
    .title {
        .t-rigth {
            span {
                margin-left: 10px;
            }
        }
    }
}
</style>
